import React from "react";
import AboutMe from "./AboutMe";

const Information = () => {
  return (
    <div className="information">
      <AboutMe />
    </div>
  );
};

export default Information;
